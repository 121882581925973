import React from "react";
import Block from "./block";

const HeroText = ({ bg, className }) => {
  return (
    <Block>
      <div className="bloom-ht bloom-w-10/12 sm:bloom-w-full">
        Better services start with putting{` `}
        <span className={`bloom-relative bloom-inline-block bloom-hero-index`}>
          <del>technology</del>
          <ins>people</ins>
        </span>
        &nbsp;first.
      </div>
    </Block>
  );
};

export default HeroText;
