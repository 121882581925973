import React from "react";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";

import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";

const Hero = ({ bg, className }) => {
  return (
    <BackgroundImage
      backgroundColor={`transparent`}
      className={className}
      fluid={bg.childImageSharp.fluid}
      Tag="section"
    ></BackgroundImage>
  );
};
const StyledBackgroundSection = styled(Hero)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`;

export default Hero;
