import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroText from "../components/heroText";
import Hero from "../components/hero";
import Block from "../components/block";

import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";

function SuccessPage({ location, data }) {
  const content = data.successPage.frontmatter;

  let contentHTML = remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(content.thankYou.content)
    .toString();

  let titleHTML = remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(content.thankYou.title)
    .toString();

  return (
    <Layout location={location}>
      <SEO keywords={[`bloom`, `bloom works`]} title="Bloom Works" />
      <Block className={`bloom-bg-white`}>
        <div
          className={`bloom-flex bloom-flex-wrap bloom--mx-6 bloom-items-center`}
        >
          <div className={`bloom-w-full sm:bloom-w-3/4 bloom-px-6`}>
            <div
              className={`bloom-text-3xl bloom-mb-5 bloom-leading-tight bloom-font-bold`}
              dangerouslySetInnerHTML={{ __html: titleHTML }}
            />
            <div
              className={`bloom-text-xl bloom-mb-12`}
              dangerouslySetInnerHTML={{ __html: contentHTML }}
            />
            <div className="bloom-flex bloom-flex-wrap bloom-relative">
              <a
                className={`bloom-bg-green bloom-border-none bloom-text-white bloom-mr-3 bloom-px-20 bloom-py-5 bloom-leading-tight bloom-font-bold hover:bloom-shadow-md`}
                href="/"
              >
                &larr; {content.thankYou.button}
              </a>
            </div>
          </div>
        </div>
      </Block>
      <Hero
        bg={content.hero.image}
        className={`bloom-pt-hero lg:bloom-pt-heroXL`}
      />
    </Layout>
  );
}

export const pageQuery = graphql`
  query SuccessTemplate {
    successPage: markdownRemark(
      frontmatter: { templateKey: { eq: "success" } }
    ) {
      html
      frontmatter {
        hero {
          image {
            childImageSharp {
              fluid(maxWidth: 2000, maxHeight: 750, cropFocus: SOUTH) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        thankYou {
          title
          content
          button
        }
      }
    }
  }
`;

export default SuccessPage;
